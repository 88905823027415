import React from "react";
import useMagicCursor from "../../hook/magicCursor";
import style from "./index.module.css";

const Cursor = () => {
  useMagicCursor(".contact-area");
  return (
    <div id="magic-cursor">
      <div id="ball" className={style.mobileCursor}></div>
    </div>
  );
};

export default Cursor;
