import { TweenMax } from "gsap";
import { useEffect } from "react";
import $ from "jquery";

let pos = { x: 0, y: 0 };
let mouse = { x: 0, y: 0 };
let active = false;
const ratio = 0.09;

function mouseMove(e) {
  mouse.x = e.clientX;
  mouse.y = e.clientY;
}

function updatePosition() {
  var ball = document.getElementById("ball");
  if (!active) {
    pos.x += (mouse.x - pos.x) * ratio;
    pos.y += (mouse.y - pos.y) * ratio;
    TweenMax.set(ball, {
      xPercent: -50,
      yPercent: -50,
      x: pos.x,
      y: pos.y,
    });
  }
}

function callParallax(e, parent) {
  parallaxIt(e, parent, parent.querySelector(".text-hover"), 85);
}

function parallaxIt(e, parent, target, movement) {
  let boundingRect = parent.getBoundingClientRect();
  let relX = e.clientX - boundingRect.left;
  let relY = e.clientY - boundingRect.top;
  let toX = ((relX - boundingRect.width / 2) / boundingRect.width) * movement;
  let toY = ((relY - boundingRect.height / 2) / boundingRect.height) * movement;
  const distance = 20;
  toX = toX > distance ? distance : toX < -distance ? -distance : toX;
  toY = toY > distance ? distance : toY < -distance ? -distance : toY;
  TweenMax.to(target, 0.3, {
    x: toX,
    y: toY,
    ease: "power2.easeOut",
  });
}

function parallaxCursor(e, parent, movement) {
  var ball = document.getElementById("ball");
  let rect = parent.getBoundingClientRect();
  let relX = e.clientX - rect.left;
  let relY = e.clientY - rect.top;
  pos.x = rect.left + rect.width / 2 + (relX - rect.width / 2) / movement;
  pos.y = rect.top + rect.height / 2 + (relY - rect.height / 2) / movement;
  TweenMax.to(ball, 0.3, {
    x: pos.x,
    y: pos.y,
  });
}

export default function useMagicCursor(floatArea) {
  function handleAddCursor() {
    // const ball = document.getElementById("ball");
    document.addEventListener("mousemove", mouseMove);
    TweenMax.ticker.addEventListener("tick", updatePosition);
  }

  function handleRemoveCursor() {
    document.removeEventListener("mousemove", mouseMove);
  }

  function handleText() {
    const ball = document.getElementById("ball");
    $(floatArea).mouseenter(function (e) {
      TweenMax.to(this, 0.3, {
        scale: 1.05,
      }); //scale trigger element

      TweenMax.to(ball, 0.3, {
        scale: 3.3,
      }); //scale ball
      TweenMax.to(ball, 0.3, {
        borderWidth: ".5px",
      });
      active = true;
    });
    $(floatArea).mouseleave(function (e) {
      TweenMax.to(this, 0.3, {
        scale: 1,
      });
      TweenMax.to(ball, 0.3, {
        scale: 1,
      });
      TweenMax.to(this.querySelector(".text-hover"), 0.3, {
        x: 0,
        y: 0,
      });
      TweenMax.to(ball, 0.3, {
        borderWidth: "1px",
      });
      active = false;
    });
    $(floatArea).mousemove(function (e) {
      parallaxCursor(e, this, 1); //magnetic ball = low number is more attractive
      callParallax(e, this);
    });
  }

  useEffect(() => {
    if (document.documentElement.clientWidth > 576) {
      handleAddCursor();
      handleText();
    }
    return () => {
      handleRemoveCursor();
    };
  }, []);
}
